<template>
  <div
    :class="['data-source-abs']"
    v-if="open">
    <li class="select-all-option" v-if="type === 'extendible-multi-selector' && ((searchText && localSearchedResult.length) || !searchText)">
      <label>
        <input
          :type="inputType"
          id="selectAll"
          v-model="isSelected"
          @click="selectAllData(!isSelected)" />
        <p>{{ 'Select All' }}</p>
      </label>
    </li>
    <ul v-if="list.length && ((searchText && localSearchedResult.length) || !searchText)" class="menu">
      <li class="select-all-option" v-if="type !== 'extendible-multi-selector' && inputType === 'checkbox' ">
        <label>
          <input
            :type="inputType"
            id="selectAll"
            v-model="isSelected"
            @click="selectAllData(!isSelected)" />
          <p>{{ 'Select All' }}</p>
        </label>
      </li>
      <li
        v-for="(object,index) of localSearchedResult.length?localSearchedResult:list"
        :key="object[name]">
        <label>
          <input
            :type="inputType"
            :id="index"
            :name="radioInputName"
            :value="object"
            @click="addId(object)"
            v-model="localSelected" />
          <p>{{ object[name]+ (showDataLink?'  -  '+object[dataLinkLabel]:'') }}</p>
        </label>
      </li>
    </ul>
    <p
      class="no-data"
      v-else-if="list.length">No items match your search</p>
      <p
      class="no-data"
      v-else>No Data</p>
  </div>
</template>

<script>
export default {
  name: 'CommonSelection',
  // props: ['open', 'list', 'name', 'selected', 'onChange', 'searchText', 'showDataLink', 'dataLinkLabel', 'focus', 'blankOptionRequired'],
  props: {
    /** This prop indicates whether the searchable dropdown is open or not */
    open: Boolean,
    /** This prop contains the data/items to be displayed in the dropdown */
    list: Array,
    /** This prop indicates the key by which the name of the list item is extracted   */
    name: String,
    /** This prop indicates what all options have been selected */
    selected: [Array, Object],
    /** This prop decides the behaviour when an option is selected/deselected */
    onChange: Function,
    /** This prop passes in the text that needs to be searched within the list */
    searchText: String,
    showDataLink: Boolean,
    dataLinkLabel: String,
    focus: Function,
    blankOptionRequired: Boolean,
    type: String,
    inputType: {
      type: String,
      default: 'checkbox'
    },
    radioInputName: String
  },
  data () {
    return {
      isSelected: false,
      localSelected: null,
      localSearchedResult: []
    }
  },
  watch: {
    selected () {
      this.localSelected = this.selected
      this.onChangeSearchText()
    },
    localSelected () {
      if (this.list && this.list.length) {
        this.$emit('onChange', this.localSelected)
        // console.log('Selected: ', this.localSelected)
      }
    },
    searchText () {
      this.localSearchedResult = []
      if (this.searchText && this.searchText.length) {
        for (const data of this.list) {
          if (data[this.name].toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1) {
            this.localSearchedResult.push(data)
          }
        }
      }
      this.onChangeSearchText()
    },
    list () {
      this.onChangeSearchText()
    },
    isSelected (newValue, oldValue) {
      this.$emit('allSelected', newValue)
    }
  },
  mounted () {
    this.localSelected = this.selected
    this.onChangeSearchText()
  },
  methods: {
    /** This method indicates that an option has been selected */
    addId (object, list) {
      // This object parameter is new. Got to check where all it may affect things
      this.$emit('focus', object, list)
    },
    /** This method selects all options in the dropdown list */
    selectAllData (isSelected) {
      this.addId()
      if (isSelected && !this.localSearchedResult.length) {
        this.localSelected = this.list
        this.addId(this.localSelected, this.list)
      } else if (isSelected && this.localSearchedResult.length) {
        for (const data of this.localSearchedResult) {
          const check = this.localSelected.filter((object) => object[this.name] === data[this.name])
          if (!check.length) {
            this.localSelected.push(data)
            this.addId(this.localSelected, this.list)
          }
        }
      } else if (!isSelected && !this.localSearchedResult.length) {
        this.localSelected = []
        this.addId(this.localSelected, this.list)
      } else if (!isSelected && this.localSearchedResult.length) {
        for (const data of this.localSearchedResult) {
          this.localSelected = this.localSelected.filter((object) => object[this.name] !== data[this.name])
          this.addId(this.localSelected, this.list)
        }
      }
    },
    /** This method filters the list based on the text that's entered in the search field */
    onChangeSearchText () {
      if (this.inputType === 'checkbox') {
        if (!this.localSearchedResult.length) {
          if (
            this.selected.length === this.list.length &&
            this.selected.length > 0
          ) {
            this.isSelected = true
          } else {
            this.isSelected = false
          }
        } else {
          let temp = true
          for (const data of this.localSearchedResult) {
            const check = this.localSelected.filter((object) => object[this.name] === data[this.name])
            if (!check.length) {
              temp = false
              break
            }
          }
          if (temp) {
            this.isSelected = true
          } else {
            this.isSelected = false
          }
        }
        this.localSelected = this.selected
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .data-source-abs {
    position: absolute;
    min-width: 100px;
    top: 100%;
    z-index: 100;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    // background: white;
    background: var(--dropdown-backgroud);
    color: var(--secondary-text-color);
    // & > ul > li:hover {
    //   background-color: var(--dropdown-background-hover);
    // }
    max-height: 250px;
    overflow-y: auto;
    .no-data {
      padding: 5px;
      font-family: 'Quicksand';
      text-align: center;
      color: lightgray;
      font-size: 14px;
    }
    ul {
      list-style-type: none;
      margin: 0px;
      li {
        display: flex;
        align-items: center;
        label {
          display: flex;
          align-items: center;
          margin: 0px;
          font-size: 12px;
          padding: 5px 15px 5px 10px;
          font-family: Quicksand;
          width: 100%;
          p {
            padding-left: 5px;
          }
        }
        &:first-of-type {
          label {
            padding-top: 10px;
            .checkmark {
              top: 12px;
            }
            .radio-checkmark {
              top: 11px;
            }
          }
        }
        &:last-child {
          label {
            padding-bottom: 10px;
          }
        }
      }
    }
    ul {
      // padding: 5px 0px;
    }
  }
</style>
