<template>
  <div class="new-clients-container">
    <admin-header-vue alias="" pageName="Update Category" />
    <form @submit.prevent="handleSubmit">
      <div class="new-client">
        <div class="columns">
          <div class="column is-4">
            <input-vue
              :required="true"
              :value="category.categoryName"
              :min="1"
              type="text"
              :max="150"
              :submitted="submitted"
              @onChangeName="onChangeName"
              label="Category Name" />
          </div>
          <div class="column is-4">
            <div class="form-group">
              <p class="display-flex">
                Product Name <sup class="astrik">*</sup>
              </p>
              <dropdown-wrap-vue @onClickOutside="onClickOutside">
                <div class="relative">
                  <b-input
                    type="search"
                    ref="products"
                    @focus="onFocus"
                    :icon-right="openProduct ? 'menu-up' : 'menu-down'"
                    v-model="searchProduct"
                    class="form-control" />
                  <div
                    v-if="submitted && !category.productId.length"
                    class="required-error">
                    Product Name is required
                  </div>
                  <common-selection-vue
                    :searchText="searchProduct"
                    name="product_name"
                    @focus="onFocus"
                    :open="openProduct"
                    :selected="category.productId"
                    @onChange="onChangeProductId"
                    :list="productList" />
                </div>
                <closable-tags-vue
                  @onChange="onRemoveProductName"
                  name="product_name"
                  :selected="category.productId" />
              </dropdown-wrap-vue>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-6">
            <input-vue
              :required="false"
              type="textarea"
              :value="category.description"
              :submitted="submitted"
              @onChangeName="onChangeDescription"
              label="Description" />
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="submit-button">
              <b-button @click.prevent="handleSubmit">Update</b-button>
              <b-button @click.prevent="handleCancel">Cancel</b-button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import DropdownWrapVue from '../../../../components/Common/DropdownWrap.vue'
import CommonSelectionVue from '../../../../components/Dropdowns/CommonSelection.vue'
import AdminHeaderVue from '../../components/AdminHeader.vue'
import ClosableTagsVue from '../../components/ClosableTags.vue'
import InputVue from '../../components/Input.vue'
import Snackbar from '../../../../components/Snackbar'

/** This component contains the code to update an existing category */
export default {
  name: 'updateCategory',
  components: {
    AdminHeaderVue,
    InputVue,
    DropdownWrapVue,
    CommonSelectionVue,
    ClosableTagsVue
  },
  data () {
    return {
      category: {
        categoryName: null,
        categoryId: null,
        productId: [],
        deletedProductId: [],
        description: null
      },
      submitted: false,
      isValidName: false,
      isDescriptionValid: true,
      openProduct: false,
      searchProduct: null
    }
  },
  computed: {
    ...mapState('categories', ['categoryDetails']),
    ...mapState('products', ['productList'])
  },
  watch: {
    categoryDetails () {
      this.category.categoryName = this.categoryDetails.category_name
      this.category.productId = this.categoryDetails.productId
      this.category.description = this.categoryDetails.description
      this.category.deletedProductId = []
    }
  },
  mounted () {
    this.getProductList()
    this.category.categoryId = Number(this.$route.params.id)
    this.getSingleCategory(this.$route.params.id)
  },
  methods: {
    ...mapActions('categories', ['getSingleCategory', 'updateCategory']),
    ...mapActions('products', ['getProductList']),
    /** This method defines the behavior for when the category name (Input value) is changed
     * @param name {String} - The name of the new category
     * @param isValid {Boolean}
     * @public
     */
    onChangeName (name, isValid) {
      this.category.categoryName = name
      this.isValidName = isValid
    },
    onChangeDescription (description, isValid) {
      this.category.description = description
      this.isDescriptionValid = isValid
    },
    /** This method checks to see if any changes have been made to existing values
     * @public
     */
    checkChanges () {
      if (this.categoryDetails.category_name !== this.category.categoryName) {
        return false
      } else if (this.category.deletedProductId.length) {
        return false
      } else if (this.categoryDetails.productId.length !== this.category.productId.length) {
        return false
      } else if (this.categoryDetails.description !== this.category.description) {
        return false
      }
      return true
    },
    /** This method submits the form and displays the success/failure message in the Snackbar
     * @public
     */
    handleSubmit () {
      this.submitted = true
      if (!this.checkChanges()) {
        if (!(this.isValidName || !this.category.productId.length)) {
          const object = {
            categoryName: this.category.categoryName,
            productId: this.category.productId.map((data) => data.product_id),
            categoryId: this.category.categoryId,
            description: this.category.description
          }
          const deletedProductId = []
          for (const product of this.categoryDetails.productId) {
            const check = this.category.productId.filter(
              (data) => data.product_id === product.product_id
            )
            if (!check.length) {
              deletedProductId.push(product.product_id)
            }
          }
          object.deletedProductId = deletedProductId
          this.updateCategory(object)
        }
      } else {
        Snackbar({ message: 'No Updates Found.', type: 'is-danger' })
      }
    },
    /** This method closes the product list dropdown if it is open
     * @public
     */
    onClickOutside () {
      this.openProduct = false
    },
    /** This method adds a product to the list of selected products (category array)
     * @param name {String} - The name of the product
     * @param isValid {Boolean}
     * @public
     */
    onChangeProductId (name, isValid) {
      this.category.productId = name
    },
    /** This method removes a product from the list of selected products
     * @param object {Object} - The category object that stores the names and ids of selected products
     * @public
     */
    onRemoveProductName (object) {
      this.category.productId = this.category.productId.filter(
        (id) => id.product_id !== object.product_id
      )
    },
    /** This method opens the product list dropdown when it is focused on
     * @public
     */
    onFocus () {
      this.$refs.products.$el.firstChild.focus()
      this.openProduct = true
    },
    /** This method redirects the user back to the category page
     * @public
     */
    handleCancel () {
      this.$router.push('/admin/category')
    }
  }
}
</script>

<style lang="scss" scoped>
.new-clients-container {
    margin-top: 20px;
    background: var(--primary);
    border-radius: 4px;
  .new-client {
    .columns {
      margin: 0px;
      .column {
        .relative {
          position: relative;
        }
        .required-error {
          color: red;
          position: absolute;
          font-size: 12px;
        }
        .display-flex {
          display: flex;
          margin: 0px;
          color: #7f8fa4;
          font-size: 12px;
          font-family: Quicksand;
          padding: 0px 20px 0px 0px;
          display: flex;
          line-height: 20px;
          .astrik {
            color: red;
          }
        }
        .upload {
          width: 200px;
          height: 60px;
          align-items: center;
          justify-content: center;
          border: 1px solid #dbdbdb;
          border-radius: 4px;
          img {
            width: 200px;
            height: 60px;
            object-fit: contain;
            padding: 5px;
          }
        }
        .submit-button {
          display: flex;
          justify-content: flex-start;
          padding-top: 20px;
          .button {
            height: 100%;
            padding: 5px 10px;
            background: #2780eb;
            color: white;
            border: none;
            margin-right: 10px;
          }
        }
      }
    }
  }
}

::v-deep .form-group {
  .textarea:focus, .textarea:hover {
    border: var(--tertiary-border-color);
  }
}
</style>
