<template>
  <div class="close-able-tags-container">
    <div v-for="(object, index) in selected" :key="index">
      <div class="single-tag">
        <p>{{(name?object[name]:object)+ (showDataLink?'  -  '+object[dataLinkLabel]:'') }}</p>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 492 492" v-bind:svg-inline="''" v-if="canClose" @click="onClickRemoveTag(object)" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M300.188 246L484.14 62.04c5.06-5.064 7.852-11.82 7.86-19.024 0-7.208-2.792-13.972-7.86-19.028L468.02 7.872C462.952 2.796 456.196.016 448.984.016c-7.2 0-13.956 2.78-19.024 7.856L246.008 191.82 62.048 7.872C56.988 2.796 50.228.016 43.02.016c-7.2 0-13.96 2.78-19.02 7.856L7.872 23.988c-10.496 10.496-10.496 27.568 0 38.052L191.828 246 7.872 429.952C2.808 435.024.02 441.78.02 448.984c0 7.204 2.788 13.96 7.852 19.028l16.124 16.116c5.06 5.072 11.824 7.856 19.02 7.856 7.208 0 13.968-2.784 19.028-7.856l183.96-183.952 183.952 183.952c5.068 5.072 11.824 7.856 19.024 7.856h.008c7.204 0 13.96-2.784 19.028-7.856l16.12-16.116c5.06-5.064 7.852-11.824 7.852-19.028 0-7.204-2.792-13.96-7.852-19.028L300.188 246z"/></svg>
      </div>
    </div>
  </div>
</template>

<script>
/** This component contains the text for the display of the *selected* options of the admin panel searchable-multi-select dropdown. Selected options are shown as tags underneath the input, and can be deselected without opening the dropdown again. All the user has to do, is click on the cross icon on the tags to deselect them in the list */
export default {
  name: 'ClosableTags',
  props: {
    /** This prop indicates the options that were selected */
    selected: {
      type: Array,
      default: null
    },
    /** This prop defines the behavior that is to be followed when an option is selected or deselected */
    onChange: {
      type: Function
    },
    /** This prop contains the name of the key used to extract the data value */
    name: {
      type: String
    },
    /** This prop indicates whether a tag can be deleted in the manner defined above (without opening the dropdown) */
    canClose: {
      type: Boolean,
      default: true
    },
    /** This prop indicates whether or not a tag should show any linked data (eg. in the case of custom categories and topics - whether the topics of a custom category should display which category they originially belonged to) */
    showDataLink: {
      type: Boolean,
      default: false
    },
    /** This prop contains the text that is linked to the tag, if there is any */
    dataLinkLabel: {
      type: String,
      default: ''
    }
  },
  methods: {
    /** This method removes the tag, and triggeres a deselect event */
    onClickRemoveTag (object) {
      this.$emit('onChange', object)
    }
  }
}
</script>

<style lang="scss" scoped>
.close-able-tags-container {
  display: flex;
  flex-wrap: wrap;
  .single-tag {
    display: flex;
    align-items: center;
    background-color:var(--tags-background-color);
    color: var(--description-light);
    border-radius: 4px;
    padding: 0px 2px;
    margin: 3px 3px 0px 0px;
    p {
      font-size: 14px;
      padding-right: 3px;
      margin: 0px;
    }
    svg {
      width: 9px;
      height: 9px;
      outline: none;
      fill: var(--dropdown-images-backgroud);
      cursor: pointer;
    }
  }
}
</style>
